import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
const totalPages = 46;

export default function Book({ lang }) {
  const flipBookRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:664px)");
  const [read, setRead] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1); // Update current page state
      flipBookRef.current.pageFlip().flipNext(); // Flip to the next page
    }
  };

  // Function to go to the previous page
  const goToPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1); // Update current page state
      flipBookRef.current.pageFlip().flipPrev(); // Flip to the previous page
    }
  };

  return (
    <>
      <Grid
        xs={12}
        item
        sx={{
          display: "none",
          "@media(max-width:600px)": {
            position: "relative",
            display: "block",
            background: "#fff",
            padding: "36px",
          },
        }}
      >
        <Typography
          className="MB"
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          A very normal girl, just <br /> like any other typical <br />{" "}
          Vietnamese girl...
        </Typography>
        <Typography
          className="MM"
          sx={{
            fontSize: "14px",
            lineHeight: "18px",
            margin: "36px 0 52px",
            "@media (max-width: 600px)": {
              fontSize: "12px",
              margin: "12px 0 28px",
            },
          }}
        >
          Một cô gái hết bình thường và <br /> giống như bao người phụ nữ Việt
          Nam <br />
          điển hình khác
        </Typography>
        <Typography
          className="LCS"
          sx={{
            fontSize: "56px",
            textTransform: "uppercase",
            letterSpacing: "-4px",
            width: "78%",
            lineHeight: "72px",
            "@media (max-width: 600px)": {
              fontSize: "30px",
              lineHeight: "35px",
              letterSpacing: "normal",
            },
          }}
        >
          has learned <br /> how to forgive <br /> herself.
        </Typography>
        <Typography
          className="MM"
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "18px",
              lineHeight: "24px",
              mt: "12px",
            },
          }}
        >
          đã học được cách tha <br /> thứ cho mình
        </Typography>
      </Grid>
      {!read ? (
        <Grid
          xs={12}
          item
          sx={{
            overflow: "hidden",
            background: "#fff",
            position: "relative",
            zIndex: 10,
            pt: "120px",
            display: "flex",
            justifyContent: "center",
            pb: "120px",
            "@media (max-width: 600px)": {
              padding: "120px 24px",
            },
          }}
        >
          <div className="wrap" style={{ position: "absolute", top: 0 }}>
            <button className="button-ring" onClick={() => setRead(!read)}>
              Discover
              <br />
              My jorney
            </button>
          </div>
          <Box
            sx={{
              maxWidth: "680px",
              width: "100%",
              padding: "12px",
              background: "#000",
              cursor: "pointer",
            }}
          >
            <img
              style={{ width: "100%" }}
              src="/img/kyduyenbio_VIE+ENGcover.png"
              alt=""
            />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid
            xs={12}
            item
            sx={{
              // overflow: "hidden",
              background: read ? "#000" : "#fff",
              position: "relative",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              p: read ? "12px 12px 76px" : "120px 0",
            }}
          >
            <HTMLFlipBook
              clickEventForward
              ref={flipBookRef}
              mobileScrollSupport
              width={
                isSmallScreen
                  ? Math.abs(window.innerWidth * 0.3 - window.innerWidth)
                  : 680
              }
              height={
                isSmallScreen
                  ? Math.abs(window.innerWidth * 0.3 - window.innerWidth)
                  : 680
              }
              style={{
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                m: "0 auto",
                padding: "12px",
                background: "12px",
              }}
              page={currentPage}
            >
              {[...Array(46)].map((_, i) => {
                const pageIndex = Math.floor(i / 2) + 1;
                return (
                  <div className="page" key={i} style={{ background: "#fff" }}>
                    <img
                      src={`https://files.muv2024.vn/kyduyenbio/${
                        !lang ? "vi" : "en"
                      }/kyduyenbio_${pageIndex}-${i % 2 ? 2 : 1}.jpg`}
                      alt={`Page ${i + 1}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                );
              })}
            </HTMLFlipBook>
          </Grid>
          <Grid
            xs={12}
            item
            sx={{
              // overflow: "hidden",
              background: "#fff",
              position: "relative",
              zIndex: 10,
              display: "flex",
              justifyContent: "center",
              p: read ? "12px 12px 76px" : "120px 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                position: "relative",
                zIndex: 12,
                alignItems: "center",
              }}
            >
              <IconButton
                color="#000"
                onClick={goToPrevPage}
                disabled={currentPage === 0} // Disable if on the first page
                sx={{ marginRight: "10px" }}
              >
                <ArrowBackIos sx={{ fontSize: "14px" }} />
              </IconButton>
              <Typography sx={{ fontSize: "14px" }}>
                {isSmallScreen
                  ? currentPage
                  : `${currentPage * 2} - ${currentPage * 2 + 1}`}{" "}
                of 45 pages
              </Typography>
              <IconButton
                onClick={goToNextPage}
                disabled={currentPage === 22} // Disable if on the last page
              >
                <ArrowForwardIos sx={{ fontSize: "14px" }} />
              </IconButton>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}
