import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ArrowSvg from "./svg/ArrowSvg";
const uppercase = { textTransform: "uppercase" };
export default function Header({ handleChangeLang, lang }) {
  return (
    <Grid
      container
      sx={{
        p: "48px !important",
        "@media (max-width: 899px)": {
          p: "0!important",
        },
      }}
    >
      {/* LANGUAGE */}
      <Grid xs={12} item textAlign="right" mb="24px" sx={{ zIndex: 10 }}>
        <label className="language-switcher">
          <input
            checked={lang}
            value={lang}
            onChange={handleChangeLang}
            type="checkbox"
            id="language-toggle"
          />
          <span className="slider round"></span>
          <span className="select-fr MM">VI</span>
          <span className="select-en MM">EN</span>
        </label>
      </Grid>
      {/* INTRO + IMG */}
      <Grid
        container
        sx={{
          "@media (max-width: 899px)": { height: "100vh" },
          "@media (max-width: 600px)": {
            height: "65vh",
          },
        }}
      >
        {/* <Grid xs={12} item>
            <Button>
                <ArrowSvg/>
            </Button>
        </Grid> */}
        {/* TEXT */}
        <Grid
          md={3.5}
          xs={12}
          item
          sx={{
            textAlign: "left",
            "@media (max-width:899px)": {
              position: "relative",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <Box
            sx={{
              "@media (max-width: 899px)": {
                background: "#fff",
                padding: "36px",
              },
            }}
          >
            <Typography
              className="LCS"
              sx={{
                fontSize: "24px",
                ...uppercase,
                "@media (max-width: 899px)": {
                  fontSize: "16px",
                },
              }}
            >
              miss universe vietnam 2024
            </Typography>
            <Box
              sx={{
                m: "12px 0",
                width: "46px",
                "@media (max-width: 899px)": {
                  width: "36px",
                },
              }}
            >
              <img style={{ width: "100%" }} src="/img/start.jpg" alt="" />
            </Box>
            <Typography
              className="MB"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                ...uppercase,
                "@media (max-width: 899px)": {
                  fontSize: "16px",
                },
              }}
            >
              miss universe vietnam{" "}
            </Typography>
            <Typography
              className="LCS"
              sx={{
                fontSize: "96px",
                lineHeight: "110px",
                m: "36px 0 60px",
                "@media (max-width: 899px)": {
                  fontSize: "72px",
                  lineHeight: "90px",
                  m: "16px 0 22px",
                  "&.MuiTypography-root > br": {
                    display: "none",
                  },
                },
                "@media (max-width: 600px)": {
                  fontSize: "36px",
                  lineHeight: "46px",
                },
              }}
            >
              Nguyễn Cao <br /> Kỳ Duyên
            </Typography>
            <Typography
              className="MB"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                "@media (max-width: 899px)": {
                  fontSize: "18px",
                  "&.MuiTypography-root > br": {
                    display: "none",
                  },
                },
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              A very normal girl, just <br /> like any other typical <br />{" "}
              Vietnamese girl...
            </Typography>
            <Typography
              className="MM"
              sx={{
                fontSize: "14px",
                lineHeight: "18px",
                margin: "36px 0 52px",
                "@media (max-width: 899px)": {
                  fontSize: "12px",
                  margin: "12px 0 28px",
                },
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              Một cô gái hết bình thường và <br /> giống như bao người phụ nữ
              Việt Nam <br />
              điển hình khác
            </Typography>
            <Typography
              className="LCS"
              sx={{
                fontSize: "56px",
                ...uppercase,
                letterSpacing: "-4px",
                width: "78%",
                lineHeight: "72px",
                "@media (max-width: 899px)": {
                  fontSize: "30px",
                  lineHeight: "35px",
                  letterSpacing: "normal",
                },
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              has learned <br /> how to forgive <br /> herself.
            </Typography>
            <Typography
              className="MM"
              sx={{
                fontSize: "28px",
                ...uppercase,
                mt: "24px",
                "@media (max-width: 899px)": {
                  fontSize: "18px",
                  lineHeight: "24px",
                  mt: "12px",
                },
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              đã học được cách tha <br /> thứ cho mình
            </Typography>
          </Box>
        </Grid>
        {/* IMG */}
        <Grid
          md={7}
          xs={12}
          item
          container
          sx={{
            position: "fixed",
            right: 0,
            "@media (max-width: 899px)": {
              height: "100vh",
              top: 0,
            },
            "@media (max-width: 600px)": {
              height: "65vh",
            },
          }}
        >
          <Grid xs={12} item container>
            {[...Array(9)].map((_, index) => {
              return (
                <Grid key={index} xs={12 / 3} item>
                  <Box
                    sx={{
                      background: `url(https://files.muv2024.vn/headers/${
                        index + 1
                      }-.png) center center / cover`,
                      height: 0,
                      paddingBottom: "100%",
                      width: "100%",
                      "@media (max-width: 899px)": {
                        pb: "125%",
                      },
                      "@media (max-width: 600px)": {
                        pb: "180%",
                      },
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
